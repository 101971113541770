<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="350"
    :retain-focus="false"
    :value="dialog"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--body">
          <h3 class="primary--text">Informe a nova data de vencimento</h3>
          <BaseDatePickerMonth
            label=""
            hide-details
            required
            :rules="[required]"
            v-model="model"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Cancelar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { InvoiceService } from '@/services/api/invoice';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    invoiceId: {
      type: String,
      require: true
    },
    dueDate: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    model: null
  }),

  created() {
    this.model = this.dueDate.substring(0, 7);
  },

  methods: {
    closeModal(search) {
      this.$emit('closeModal', search);
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const day = new Date(this.dueDate).getDate();
        const dueDate = `${this.model}-${day}T00:00:00`;

        const invoiceService = new InvoiceService();
        const { status } = await invoiceService.putInvoiceDueDate({
          invoiceId: this.invoiceId,
          dueDate
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
